import { AppConfig, Environment } from 'shared-domain';

import { environment } from '../environments/environment';
import { companyFields } from './company';
import { affiliatedCompanyFields } from './affiliated-company';
import { individualFields } from './individual';
import { stepsConfig } from './step';

export const bootstrapConfig: Partial<Omit<AppConfig, 'environment'>> & {
  environment: Environment;
} = {
  websiteTitle: 'xMoney',
  companyFields,
  affiliatedCompanyFields,
  individualFields,
  stepsConfig,
  environment,
  redirectUrl: 'https://dotfile.com',
};
